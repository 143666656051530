@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&family=Lato:wght@300;400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: "Lato", sans-serif; */
  color: #fff;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.page {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
}

.page h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

/* .page h3 {
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  margin: 0 10px;
  margin-bottom: 1rem;
} */

.page h3 {
  font-family: Lato, sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 1.2rem;
  max-width: 380px;
  margin-bottom: 3rem;
  color: #f8dddc;
}

/* Clock 🕰️ */
.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 30px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: #000000; /* color of digit */
  --fcc-label-font-size: 14px; /* font size of label */
  --fcc-label-color: #fff; /* color of label */
  --fcc-background: #ffffff; /* background of digit card */
  --fcc-divider-color: #000000; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: #fff; /* color of colon */

  font-family: "Orbitron";
  font-weight: 700;
  margin-bottom: 2rem;
}

.btn {
  width: 170px;
  height: 54px;
  background: #fff;
  color: #000;
  border-color: rgb(122, 114, 121);
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0.4rem;
  font-weight: 700;
  cursor: pointer;
  margin-top: 2rem;
}

.clock-work {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 30px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: #000000; /* color of digit */
  --fcc-label-font-size: 14px; /* font size of label */
  --fcc-label-color: #fff; /* color of label */
  --fcc-background: #ffffff; /* background of digit card */
  --fcc-divider-color: #000000; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  /* --fcc-separator-color: rgb(120, 231, 28); */
  --fcc-separator-color: #ff7b73; /* color of colon */
}

/* For larger screen  */
@media (min-width: 900px) {
  .page h1 {
    font-size: 5rem;
  }
  .page h3 {
    font-size: 2rem;
    max-width: 900px;
  }
}
