a {
  font-weight: bold;
  text-decoration: none;
  color: #607EAA;
}

.social{
  margin-top: 8px ;
  margin-bottom: 8px;
}
.social > li {
  display: inline-block;
  margin: 0 10px;
  font-size: 30px;
}
/* .social li a:hover  ----> this will not work */
.social li:hover {
  /* transform: scale(0.7) translateY(-15px); */
  transform: scale(1.18);
  transition: all 0.2s ease-in-out;
}



.content-footer {
  font-size: 15px;
  /* margin: 50px 0; */
  margin-top: 5rem;
  margin-bottom: 83px;
  /* padding: 20px 0; */
  /* text-align: center; */
  text-decoration: none;

  /* position: ; */
  /* padding: 10px 10px 0px 10px; */
  /* bottom: 0; */
  /* width: 100%; */
  /* Height of the footer*/
  height: 40px;
  /* background: grey; */
}

.content-footer > p {
  color: #f8dddc;
}

.copyright {
  font-size: 15px;
  color: #f8dddc;
}

.email-address {
  font-size: 16px;
  color: #f8dddc;
  margin-top: 10px;
}

.email-address a {
  color: #f8dddc;
}


/* For smaller screen  */
@media (max-width: 900px) {
  .social > li {
    margin: 0 9px;
    font-size: 30px;
  }
}
