@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

$black: #000000;
$cornflower-lilac: #f8dddc;
// $salmon: #ff7b73;
$salmon: #ff7b73;
$white: #ffffff;
$your-pink: #a07575;

body {
    font-size: 8.5px; // change value to scale
    font-family: Lato, sans-serif;
    // background-color: $salmon;
    
    margin: 1 rem;
    display: grid;
    height: 100vh;
    place-items: center;
}

#notify{
    margin-top: 70px;
}
.c-checkbox {
    display: none;

    &:checked + .c-formContainer {
        .c-form {
            width: 37.5em;
        }

        .c-form__toggle {
            visibility: hidden;
            opacity: 0;
            transform: scale(0.7);
        }

        .c-form__input,
        .c-form__buttonLabel {
            transition: 0.2s 0.1s;
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }

    &:not(:checked),
    &:checked {
        + .c-formContainer .c-form__input:required:valid ~ .c-form__toggle::before {
            content: 'Thank You! 🤩';
        }
    }

    &:not(:checked) + .c-formContainer {
        .c-form__input:required:valid ~ .c-form__toggle {
            pointer-events: none;
            cursor: default;
        }
    }
}

.c-formContainer,
.c-form,
.c-form__toggle {
    width: 20em;
    height: 6.25em;
}

.c-formContainer {
    position: relative;
    font-weight: 700;
}

.c-form,
.c-form__toggle {
    position: absolute;
    border-radius: 6.25em;
    background-color: $white;
    transition: 0.2s;
}

.c-form {
    left: 50%;
    transform: translateX(-50%);
    padding: 0.625em;
    box-sizing: border-box;
    box-shadow: 0 0.125em 0.3125em rgba($black, 0.3);

    // position form inputs
    display: flex;
    justify-content: center;
}

.c-form__toggle {
    color: $salmon;
    top: 0;
    cursor: pointer;
    z-index: 1;

    // position message
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        font-size: 1.75em;
        content: attr(data-title);
    }
}

.c-form__input,
.c-form__button {
    font: inherit;
    border: 0;
    outline: 0;
    border-radius: 5em;
    box-sizing: border-box;
}

.c-form__input,
.c-form__buttonLabel {
    font-size: 1.75em;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.7);
    transition: 0s;
}

.c-form__input {
    color: $your-pink;
    height: 100%;
    width: 100%;
    padding: 0 0.714em;

    &::placeholder {
        color: currentColor;
    }

    &:required:valid {
        color: $salmon;

        + .c-form__buttonLabel {
            color: $white;

            &::before {
                pointer-events: initial;
            }
        }
    }
}

.c-form__buttonLabel {
    color: $cornflower-lilac;
    height: 100%;
    width: auto;
    
    // accepts click events
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        cursor: pointer;
    }
}

.c-form__button {
    color: inherit;
    padding: 0;
    height: 100%;
    width: 5em;
    background-color: $salmon;
}

/* For smaller screen  */
@media (max-width: 900px) {
    body {
        font-size: 7px; // change value to scale
    }

    #notify{
        margin-top: 55px;
    }
  }